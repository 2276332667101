import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import AparatosAuditivos from "./pages/AparatosAuditivos";
import Tinnitus from "./pages/Tinnitus";
import Ajustes from "./pages/Ajustes";
import Contacto from "./pages/Contacto";
import PerdidaAuditiva from "./pages/PerdidaAuditiva";
import EstudiosAudiologicos from "./pages/EstudiosAudiologicos";
import Footer from "./components/Footer";
import ContactBar from "./components/ContactBar";
import "./sass/style.sass";
import Aviso from "./pages/Aviso";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aparatos-auditivos" element={<AparatosAuditivos />} />
        <Route path="/zumbido-de-oidos" element={<Tinnitus />} />
        <Route path="/ajustes-y-mas" element={<Ajustes />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/perdida-auditiva" element={<PerdidaAuditiva />} />
        <Route
          path="/estudios-audiologicos"
          element={<EstudiosAudiologicos />}
        />
        <Route path="/aviso-de-privacidad" element={<Aviso />} />
      </Routes>
      <Footer />
      <ContactBar />
    </>
  );
}

export default App;
