function Map() {
  return (
    <iframe
      src="https://www.google.com/maps/d/u/0/embed?mid=14lLdCAh97bEMheya6gD3MX97rW5IXI24&ehbc=004a98"
      title="Mapa de contacto"
    ></iframe>
  );
}

export default Map;
