function AccordionTab({ children, active }) {
  return (
    <button className="accordion__tab">
      <span>{children}</span>
      <span className="material-icons add">add</span>
      <span className="material-icons remove">remove</span>
    </button>
  );
}

export default AccordionTab;
