import "../sass/contact-map.sass";
import Map from "./Map";
import Wrapper from "./Wrapper";

function ContactMap() {
  return (
    <section className="contact-map">
      <Wrapper variant="xl">
        <div className="contact-map__container">
          <Map />
        </div>
      </Wrapper>
    </section>
  );
}

export default ContactMap;
