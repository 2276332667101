import "../sass/aside.sass";
import { Link, useLocation } from "react-router-dom";
import { useRef, useEffect } from "react";

function Aside({ active, setActive }) {
  const navRef = useRef();
  const location = useLocation();

  useEffect(() => {
    const links = navRef.current.querySelectorAll("li");

    links.forEach((el) => {
      el.classList.remove("active");
    });

    setActive(false);

    if (location.pathname === "/") {
      links[0].classList.add("active");
      return;
    }

    if (location.pathname === "/aparatos-auditivos") {
      links[1].classList.add("active");
      return;
    }

    if (location.pathname === "/zumbido-de-oidos") {
      links[2].classList.add("active");
      return;
    }

    if (location.pathname === "/ajustes-y-mas") {
      links[3].classList.add("active");
      return;
    }

    if (location.pathname === "/contacto") {
      links[4].classList.add("active");
      return;
    }
  }, [location, setActive]);

  return (
    <aside className={`aside ${active && "active"}`}>
      <nav className="aside__container">
        <ul ref={navRef}>
          <li>
            <Link to="/">Inicio</Link>
          </li>
          <li>
            <Link to="/aparatos-auditivos">Aparatos Auditivos</Link>
          </li>
          <li>
            <Link to="/zumbido-de-oidos">Tinnitus</Link>
          </li>
          <li>
            <Link to="/ajustes-y-mas">Ajustes y más</Link>
          </li>
          <li>
            <Link to="/contacto">Contacto</Link>
          </li>
        </ul>
      </nav>
      <div className="aside__close" onClick={() => setActive(false)}>
        <span className="material-icons">close</span>
      </div>
    </aside>
  );
}

export default Aside;
