import { Link } from "react-router-dom";
import "../sass/contact-bar.sass";

function ContactBar() {
  return (
    <div className="contact-bar">
      <div className="contact-bar__icon">
        <span className="material-icons">phone_in_talk</span>
      </div>
      <div className="contact-bar__item">
        <Link to="/">55 5207-7321</Link>
      </div>
      <div className="contact-bar__item">
        <Link to="/">55 5207-7261</Link>
      </div>
      <div className="contact-bar__item">
        <Link to="/">55 5295-5132</Link>
      </div>
    </div>
  );
}

export default ContactBar;
