import "../sass/page-title.sass";
import video from "../static/sound-1.mp4";

function PageTitle({ title, subtitle }) {
  return (
    <div className="page-title">
      <div className="page-title__content">
        <div className="page-title__title">
          <h1>{title}</h1>
          {subtitle && <p>{subtitle}</p>}
        </div>
      </div>
      <video autoPlay loop muted playsInline>
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
}

export default PageTitle;
