import "../sass/levels.sass";

function Levels() {
  return (
    <div className="levels">

      <div className="levels__top">
        <div className="levels__top__item">
          <div className="levels__top__item__label">26 - 40</div>
          <div className="levels__top__item__bar--100"></div>
        </div>
        <div className="levels__top__item">
          <div className="levels__top__item__label">41 - 60</div>
          <div className="levels__top__item__bar--200"></div>
        </div>
        <div className="levels__top__item">
          <div className="levels__top__item__label">61 - 80</div>
          <div className="levels__top__item__bar--300"></div>
        </div>
        <div className="levels__top__item">
          <div className="levels__top__item__label">Más de 80</div>
          <div className="levels__top__item__bar--400"></div>
        </div>
      </div>

      <div className="levels__title">Grados de pérdida auditiva en decibeles (dB)</div>

      <div className="levels__bottom">
        <div className="levels__bottom__item">
          <div className="levels__bottom__item__label">26 - 40</div>
          <div className="levels__bottom__item__card--100">
            <div className="levels__bottom__item__title">Leve</div>
            <p>
              Una persona con este nivel de pérdida auditiva tendrá problemas a
              la hora de entender palabras en tono bajo, conversaciones en
              distancia o con ruido de fondo.
            </p>
          </div>
        </div>
        <div className="levels__bottom__item">
          <div className="levels__bottom__item__label">41 - 60</div>
          <div className="levels__bottom__item__card--200">
            <div className="levels__bottom__item__title">Moderada</div>
            <p>
              Una persona con este nivel de pérdida auditiva tendrá dificultades
              a la hora de entender palabras en un tono normal, incluso a corta
              distancia.
            </p>
          </div>
        </div>
        <div className="levels__bottom__item">
          <div className="levels__bottom__item__label">61 - 80</div>
          <div className="levels__bottom__item__card--300">
            <div className="levels__bottom__item__title">Severa</div>
            <p>
              Una persona con este nivel de pérdida auditiva podría escuchar
              voces y sonidos de un tono alto. La mayor parde de las
              conversaciones no las ecuchara.
            </p>
          </div>
        </div>
        <div className="levels__bottom__item">
          <div className="levels__bottom__item__label">Más de 80</div>
          <div className="levels__bottom__item__card--400">
            <div className="levels__bottom__item__title">Profunda</div>
            <p>
              Una persona con este nivel de pérdida auditiva solo percibirá
              sonidos muy altos y vibraciones.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Levels;
