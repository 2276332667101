import "../sass/highlights.sass";
import HighlightsItem from "./HighlightsItem";
import Wrapper from "./Wrapper";

function Highlights() {
  return (
    <section className="highlights">
      <Wrapper variant="xl">
        <div className="highlights__content">
          <HighlightsItem
            title="Pérdida Auditiva"
            description="La pérdida de audición puede afectar a uno o a ambos oídos y
                ocasionar dificultades para oír una conversación o determinados
                sonidos."
            link ="/perdida-auditiva"
          />
          <HighlightsItem
            title="Tinnitus"
            subtitle="(Zumbido de oídos)"
            description="El Tinnitus afecta alrededor del 17% de la población mundial y
                en algunos de ellos puede provocar un sufrimiento significativo."
            link="/zumbido-de-oidos"
          />
          <HighlightsItem
            title="Estudios Audiológicos"
            description="Audiometría, Timpanometría, Emisiones otocacústicas, Potenciales
                evocados, Videonistagmografía"
            important={true}
            link="/estudios-audiologicos"
          />
        </div>
      </Wrapper>
    </section>
  );
}

export default Highlights;
