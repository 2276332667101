import { useEffect, useState } from "react";
import "../sass/footer.sass";
import iconEvolucion from "../static/icono-evolucion-auditiva.svg";
import { Link } from "react-router-dom";

function Footer() {
  const [year, setYear] = useState();

  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  return (
    <footer className="footer">
      <div className="footer__icon">
        <img src={iconEvolucion} alt="Icono Evolución Auditiva" />
      </div>
      <p>© Evolución Auditiva, México {year}</p>

      <p>
        Aviso de Publicidad 223300202A0844 <span>|</span>{" "}
        <Link to="/aviso-de-privacidad">Aviso de Privacidad</Link><br/>
        Responsable Sanitario: Dra. Mónica Segura Hernández  Ced. Esp. 10958013
      </p>
    </footer>
  );
}

export default Footer;
