import { useEffect, useRef } from "react";
import { useLocation, Link } from "react-router-dom";
import "../sass/navbar.sass";
import Wrapper from "./Wrapper";

function Navbar() {
  const navRef = useRef();
  const location = useLocation();

  useEffect(() => {
    const links = navRef.current.querySelectorAll("li");

    links.forEach((el) => {
      el.classList.remove("active");
    });

    if (location.pathname === "/") {
      links[0].classList.add("active");
      return;
    }

    if (location.pathname === "/aparatos-auditivos") {
      links[1].classList.add("active");
      return;
    }

    if (location.pathname === "/zumbido-de-oidos") {
      links[2].classList.add("active");
      return;
    }

    if (location.pathname === "/ajustes-y-mas") {
      links[3].classList.add("active");
      return;
    }

    if (location.pathname === "/contacto") {
      links[4].classList.add("active");
      return;
    }
  }, [location]);

  return (
    <nav className="navbar">
      <Wrapper variant="xl">
        <nav className="navbar__container">
          <ul ref={navRef}>
            <li>
              <Link to="/">Inicio</Link>
            </li>
            <li>
              <Link to="/aparatos-auditivos">Aparatos Auditivos</Link>
            </li>
            <li>
              <Link to="/zumbido-de-oidos">Tinnitus</Link>
            </li>
            <li>
              <Link to="/ajustes-y-mas">Ajustes y más</Link>
            </li>
            <li>
              <Link to="/contacto">Contacto</Link>
            </li>
          </ul>
        </nav>
      </Wrapper>
    </nav>
  );
}

export default Navbar;
