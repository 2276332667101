import "../sass/container.sass";

function Container({ children, variant, className}) {
  return (
    <section className={`container ${variant} ${className} `}>
      {children}
    </section>
  );
}

export default Container;
