import { useEffect, useRef } from "react";
import "../sass/accordion.sass";

function Accordion({ children, id }) {
  const refAccordion = useRef(null);

  useEffect(() => {
    const accordion = refAccordion.current;
    const accordionItems = accordion.querySelectorAll(".accordion__panel");
    const accordionHeaders = accordion.querySelectorAll(".accordion__tab");

    accordionHeaders.forEach((header, index) => {
      header.addEventListener("click", (e) => {
        if (e.currentTarget.classList.contains("active")) {
          e.currentTarget.classList.remove("active");
          accordionItems[index].classList.remove("active");
          return;
        }

        accordionHeaders.forEach((item) => item.classList.remove("active"));
        e.currentTarget.classList.toggle("active");
        accordionItems.forEach((item) => item.classList.remove("active"));
        accordionItems[index].classList.add("active");
      });
    });
  }, []);

  return (
    <div className="accordion" ref={refAccordion} id={id}>
      {children}
    </div>
  );
}

export default Accordion;
