import "../sass/video.sass";
import video from "../static/video-hero.mp4";
import imgPoster from "../static/poster.jpg";

function Video() {
  return (
    <section className="video" poster={imgPoster}>
      <video autoPlay loop muted className="video__player">
        <source src={video} type="video/mp4" />
      </video>
    </section>
  );
}

export default Video;
