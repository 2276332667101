import "../sass/split.sass";

function Split({ image, alt, children, side, id }) {
  return (
    <article className={`split ${side}`} id={id}>
      <div className="split__left">
        <img src={image} alt={alt} />
      </div>
      <div className="split__right">{children}</div>
    </article>
  );
}

export default Split;
