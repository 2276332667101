import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Highlights from "../components/Highlights";
import Split from "../components/Split";
import Video from "../components/Video";
import Content from "../components/Content";
import Contact from "../components/Contact";
import List from "../components/List";
import ListItem from "../components/ListItem";
import Button from "../components/Button";
import imgAbout from "../static/widex-1.jpg";
import imgServices from "../static/nuestros-servicios.jpg";
import Sample from "../components/Sample";

const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Evolución Auditiva</title>
      </Helmet>
      <Video />
      <Highlights />
      <Split image={imgAbout} alt="Widex">
        <Content>
          <h2>Evolución Auditiva</h2>
          <p>
            Somos una Clínica especializada en la rehabilitación auditiva y el
            tratamiento de Tinnitus (zumbido de oídos). Contamos con más de 10
            años de experiencia entendiendo las necesidades particulares de cada
            paciente y ofreciéndoles las mejores alternativas que se adapten a
            su necesidad auditiva, a su estilo de vida y a su presupuesto.
            Nuestra misión es que su audición no limite sus actividades y puedan
            desarrollarse a plenitud.
          </p>
          <List>
            <ListItem>Personal capacitado y con actitud de servicio</ListItem>
            <ListItem>
              Damos seguimiento en la rehabilitación del paciente
            </ListItem>
            <ListItem>
              Contamos con la mejor tecnología en auxiliares auditivos y sus
              accesorios
            </ListItem>
            <ListItem>Somos distribuidores directos</ListItem>
            <ListItem>Equipo de diagnóstico con tecnología de punta</ListItem>
          </List>
        </Content>
      </Split>
      <Split image={imgServices} alt="Widex" side="right">
        <Content>
          <div>
            <h2>Nuestros Servicios</h2>
            <p className="important">Soluciones a tu alcance</p>
          </div>
          <List>
            <ListItem>
              <HashLink to="/aparatos-auditivos#asesoria-gratuita">Asesoría sobre el uso de aparatos auditivos</HashLink>
            </ListItem>
            <ListItem>
              <HashLink to="/perdida-auditiva#consultas-audiologicas">Valoración Auditiva</HashLink>
            </ListItem>
            <ListItem>
              <HashLink to="/perdida-auditiva#consultas-audiologicas">Consultas Audiológicas</HashLink>
            </ListItem>
            <ListItem>
              <Link to="/zumbido-de-oidos">Tratamiento para Tinnitus</Link>
            </ListItem>
            <ListItem>
              <Link to="/estudios-audiologicos">Estudios Audiológicos</Link>
            </ListItem>
            <ListItem>
              <Link to="/ajustes-y-mas">Moldes a la medida</Link>
            </ListItem>
            <ListItem>
              <Link to="/ajustes-y-mas">
                Reparación y mantenimiento de aparatos auditivos
              </Link>
            </ListItem>
          </List>
          <div>
            <Button
              variant="fill"
              title="Aparatos Auditivos"
              onClick={() => navigate("/aparatos-auditivos")}
            />
          </div>
        </Content>
      </Split>
      <Sample  margin="mt"/>
      <Contact />
    </>
  );
};

export default Home;
