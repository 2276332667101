import "../sass/contact.sass";
import Wrapper from "../components/Wrapper";
import Content from "./Content";
import Map from "./Map";
import ContactInfo from "./ContactInfo";

function Contact() {
  return (
    <section className="contact">
      <Wrapper variant="xl">
        <div className="contact__container">
          <div className="contact__map">
            <Map />
          </div>
          <div className="contact__info">
            <Content>
              <h2>Contacto</h2>
              <p className="important">
                Las audioprotesistas, de acuerdo a los resultados de una
                Valoración auditiva previa, podrán asesorarte sobre tu pérdida
                auditiva y sobre las mejores opciones de aparatos auditivos que
                requieras.
              </p>
              <ContactInfo icon="place">
                <p>
                  Calle Shakespeare No. 15 Piso 6, interior 601, Col. Anzures,
                  C.P. 11590, Alcaldía Miguel Hidalgo, CDMX
                </p>
              </ContactInfo>
              <ContactInfo icon="phone_in_talk">
                <p>Agenda tu cita en los teléfonos:</p>
                <p>
                  55 5207-7321 <span>|</span> 55 5207-7261
                  <span>|</span> 55 5295-5132
                </p>
              </ContactInfo>
            </Content>
          </div>
        </div>
      </Wrapper>
    </section>
  );
}

export default Contact;
