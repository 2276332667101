import { useNavigate } from "react-router-dom";

function HighlightsItem({ title, subtitle, description, link, important }) {
  let navigate = useNavigate();

  return (
    <div
      className={
        important
          ? `highlights__content__item-important`
          : `highlights__content__item`
      }
      onClick={() => navigate(link)}
    >
      <h3>
        {title} {subtitle && <span>{subtitle}</span>}
      </h3>
      <p>{description}</p>
      <span>
        <span className="material-icons">launch</span>
        <small>Leer más</small>
      </span>
    </div>
  );
}

export default HighlightsItem;
