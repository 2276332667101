import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Contact from "../components/Contact";
import PageTitle from "../components/PageTitle";
import Split from "../components/Split";
import Content from "../components/Content";
import List from "../components/List";
import ListItem from "../components/ListItem";
import imgConsult from "../static/consultas-auditivas.jpg";
import imgAdvisory from "../static/asesoria-gratuita.jpg";
import Container from "../components/Container";
import Wrapper from "../components/Wrapper";
import Levels from "../components/Levels";
import Button from "../components/Button";
import Gallery from "../components/Gallery";
import GalleryItem from "../components/GalleryItem";

import imgPeople1 from "../static/people-1.jpg";
import imgPeople2 from "../static/people-2.jpg";
import imgPeople3 from "../static/people-3.jpg";

function PerdidaAuditiva() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTitle
        title="Perdida Auditiva"
      />
      {/* <Banner /> */}
      <Gallery>
        <GalleryItem src={imgPeople1} alt="aparatos-auditivos" />
        <GalleryItem src={imgPeople2} alt="aparatos-auditivos" />
        <GalleryItem src={imgPeople3} alt="aparatos-auditivos" />
      </Gallery>
      <Container variant="gray">
        <Wrapper variant="lg">
          <Content>
            <h2>Recupere la Audición</h2>
            <p>
              La pérdida de audición puede afectar a uno o ambos oídos y
              ocasiona dificultades para oír una conversación o determinados
              sonidos. De acuerdo con cifras de la OMS publicadas en 2021*, 1500
              millones de personas en el mundo viven con algún grado de pérdida
              de audición.
            </p>
            <p>
              La pérdida de audición no tratada genera diversos problemas tales
              como:
            </p>
            <List>
              <ListItem>Dificultad en la comunicación</ListItem>
              <ListItem>Sensación de aislamiento y soledad</ListItem>
              <ListItem>Frustración, depresión y ansiedad</ListItem>
              <ListItem>Bajo rendimiento escolar y/o laboral</ListItem>
            </List>
            <p>
              El tratamiento para pérdida auditiva (hipoacusia) es, en su
              mayoría, por medio del uso adecuado de los auxiliares auditivos
              (aparatos auditivos). A pesar de esto, de acuerdo con la OMS, sólo
              un 17% de todas las personas que podrían beneficiarse los utiliza.
            </p>
            <div>
              <Button
                onClick={() => navigate("/aparatos-auditivos")}
                title="Aparatos Auditivos"
                variant="fill"
              />
              <Button
                onClick={() => navigate("/contacto")}
                title="Agenda tu cita"
                variant="outline"
              />
            </div>
          </Content>
        </Wrapper>
      </Container>
      <Container variant="white">
        <Wrapper variant="lg">
          <Content>
            <h4>Causas y grados de pérdida auditiva</h4>
            <p>
              Las causas son diversas, aunque las más comunes son la exposición
              a niveles muy altos de ruido y la edad avanzada. Según datos del
              INEGI, en la Ciudad de México y zona metropolitana, el 49.7% de la
              población mayor de 60 años tiene algún nivel de pérdida auditiva.
            </p>
            <List>
              <ListItem>Causas congénitas (hereditario o no)</ListItem>
              <ListItem>
                Causas adquiridas:
                <List>
                  <ListItem>Exposición a niveles muy altos de ruido</ListItem>
                  <ListItem>Edad avanzada</ListItem>
                  <ListItem>Traumatismos en cabeza u oídos</ListItem>
                  <ListItem>Oclusión de los conductos auditivos</ListItem>
                  <ListItem>Enfermedades infecciosas graves</ListItem>
                  <ListItem>Enfermedades cardiovasculares</ListItem>
                  <ListItem>Abuso de sustancias o fármacos ototóxicos</ListItem>
                </List>
              </ListItem>
            </List>
            <hr />
            <h4>Grados de Pérdida Auditiva</h4>
            <Levels />
          </Content>
        </Wrapper>
      </Container>
      <Split image={imgAdvisory} alt="Widex">
        <Content>
          <h2>Asesoría gratuita sobre el uso de aparatos auditivos</h2>
          <p>
            En el caso de que hayas detectado que tu nivel de audición o el de
            alguno de tus familiares ha disminuido acude con nosotros a una
            Asesoría gratuita. Las audioprotesistas, de acuerdo a los resultados
            de una Valoración auditiva previa, podrán asesorarte sobre tu
            pérdida auditiva y sobre las mejores opciones de aparatos auditivos
            que requieras. Agenda tu cita en los teléfonos:
          </p>
          <List>
            <ListItem>55 5207 7321</ListItem>
            <ListItem>55 5207 7261</ListItem>
            <ListItem>55 5295 5132</ListItem>
          </List>
          <p>
            Conoce más sobre los{" "}
            <Link to="/aparatos-auditivos">aparatos auditivos</Link> que
            manejamos
          </p>
        </Content>
      </Split>
      <Split
        image={imgConsult}
        alt="Widex"
        side="right"
        id="consultas-audiologicas"
      >
        <Content>
          <h2>Consultas Audiólogicas</h2>
          <p>
            El personal médico de Evolución Auditiva está especializado en
            Audiología, por lo que pueden diagnosticar la mayoría de
            padecimiento auditivos ya sea en niños, adolescentes o adultos y
            tratar de la mejor maneja estos padecimientos.
          </p>
          <p>
            El audiólogo es capaz de explorar la audición de los pacientes,
            medir la pérdida de la audición, detectar problemas de equilibrio,
            apoyar a los pacientes a sobrellevar o superar la pérdida auditiva,
            realizar distintos tipos de pruebas para ver si el paciente responde
            a estímulos auditivos de distinto tipo y determinan las posibles
            opciones de tratamiento. A los pacientes que sean candidatos a usar
            aparatos auditivos, como una solución a su pérdida auditiva, el
            Audiólogo los remitirá a los audioprotesistas para que les adapten
            la opción que mejor se adecúe a sus necesidades.
          </p>
          <p className="important">Valoración Auditiva que incluye:</p>
          <List>
            <ListItem>
              <strong>Otoscopía:</strong> revisión del estado de la membrana
              timpánica
            </ListItem>
            <ListItem>
              <strong>Lavado de oídos:</strong> en caso oclusión por cerumen.
            </ListItem>
            <ListItem>
              <strong>Estudios Audiológicos:</strong> Audiometría con
              Logoaudiometría
            </ListItem>
            <ListItem>
              <strong>Explicación del tipo y grado de pérdida auditiva</strong>
            </ListItem>
            <ListItem>
              <strong>Prueba de los aparatos auditivos:</strong> Experiencia en diferentes ambientes de sonido
            </ListItem>
          </List>
        </Content>
      </Split>
      <Contact />
    </>
  );
}

export default PerdidaAuditiva;
