import { useEffect } from "react";
import Contact from "../components/Contact";
import PageTitle from "../components/PageTitle";
import imgTinnitus from "../static/tinnitus.jpg";
import Content from "../components/Content";
import Split from "../components/Split";
import Wrapper from "../components/Wrapper";
import Accordion from "../components/Accordion";
import AccordionTab from "../components/AccordionTab";
import AccordionPanel from "../components/AccordionPanel";
import List from "../components/List";
import ListItem from "../components/ListItem";
import Container from "../components/Container";
import imgTeam from "../static/team.jpg";
import Gallery from "../components/Gallery";
import GalleryItem from "../components/GalleryItem";
import imgTinnitus1 from "../static/tinnitus-2.jpg";
import imgTinnitus2 from "../static/tinnitus-1.jpg";
import imgTinnitus3 from "../static/tinnitus-3.jpg";

function Tinnitus() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTitle
        title="Zumbido de oídos"
        subtitle="¿Sufre de Tinnitus o Acúfeno?"
      />
      <Gallery>
        <GalleryItem src={imgTinnitus1} alt="aparatos-auditivos" />
        <GalleryItem src={imgTinnitus2} alt="aparatos-auditivos" />
        <GalleryItem src={imgTinnitus3} alt="aparatos-auditivos" />
      </Gallery>
      <Split image={imgTinnitus} alt="Widex" id="asesoria-gratuita">
        <Content>
          <h2>¡Silencio por favor!</h2>
          <p>
            El nombre científico del Zumbido de oídos es Acúfeno en el idioma
            Español y Tinnitus en el idioma Inglés. Tradicionalmente se le
            describe como cualquier sonido generado dentro de la cabeza, sin
            tener en cuenta su origen o el mecanismo que lo produce. Sin embargo
            en 1995 P.J. Jastreboff propuso una nueva definición:
          </p>
          <p className="important">
            “El acúfeno es una percepción auditiva fantasma",
          </p>
          <p>
            Por lo tanto el Acúfeno es una actividad generada dentro del Sistema
            Nervioso sin que exista actividad vibratoria dentro de la cóclea,
            ubicada en el oído interno
          </p>
          <p>
            En algunas ocasiones los pacientes con Acúfeno son intolerantes a
            ciertos sonidos tales como: timbres, voz de alguna persona, cierta
            música, etc., llamándole a esta intolerancia Hiperacusia o
            Misofonía, padecimiento que se puede tratar y controlar. El Acúfeno
            es un problema muy común que afecta alrededor del 17% de la
            población a nivel mundial, lo que provoca un sufrimiento
            significativo en el 4% de la población general.
          </p>
        </Content>
      </Split>
      <Container variant="white">
        <Wrapper variant="lg">
          <Content>
          <h2 className="center">Preguntas frecuentes</h2>
            <Accordion>
              <AccordionTab>
                ¿Sufrir de Acúfeno significa tener Pérdida Auditiva?
              </AccordionTab>
              <AccordionPanel>
                <Content>
                  <p>
                    No necesariamente. Sin embargo, dependiendo del diagnóstico
                    y tipo de Acúfeno, sí existen casos que se correlacionan o
                    en los que se presentan ambos padecimientos, siendo por esto
                    tan importante la realización de los estudios de diagnóstico
                    para tratarlo adecuadamente.
                  </p>
                </Content>
              </AccordionPanel>
              <AccordionTab>
                ¿Cómo afecta en la vida diaria el Acúfeno?
              </AccordionTab>
              <AccordionPanel>
                <Content>
                  <p>
                    Generalmente los pacientes que experimentan cualquiera de
                    estas sensaciones, Acúfeno, Hiperacusia o Misofonia, son
                    pacientes que presentan una disminución importante en su
                    calidad de vida, ya que pueden presentar algunas de las
                    siguientes alteraciones:
                  </p>
                  <List>
                    <ListItem>Falta de concentración</ListItem>
                    <ListItem>Insomnio</ListItem>
                    <ListItem>Depresión</ListItem>
                    <ListItem>Ansiedad</ListItem>
                    <ListItem>
                      Conflictos con sus relaciones interpersonales
                    </ListItem>
                  </List>
                </Content>
              </AccordionPanel>
              <AccordionTab>
                ¿Debo aprender a vivir con el Acúfeno?
              </AccordionTab>
              <AccordionPanel>
                <Content>
                  <p>
                    Normalmente atendemos a pacientes que han visto una variedad
                    de médicos con diversas especialidades, cuyas conclusiones
                    finales es decirle al paciente que deben "aprender a vivir
                    con ello”. Pero en la actualidad gracias al avance médico y
                    tecnológico ahora ya existe un tratamiento que quita o
                    disminuye significativamente la presencia del molesto
                    Acúfeno.
                  </p>
                  <p>
                    Evolucion Auditiva es la primera Clínica en México
                    especializada en el tratamiento del Acúfeno o Tinnitus, la
                    cual está certificada por la Fundación Jastreboff Hearing
                    Disorders; Emory University.
                  </p>
                </Content>
              </AccordionPanel>
              <AccordionTab>Valoración de acúfeno (Tinnitus)</AccordionTab>
              <AccordionPanel>
                <Content>
                  <p>
                    Si Usted o algún familiar padece de Zumbido de Oídos le
                    sugerimos que nos permita ayudarle a mejorar su condición y
                    a recuperar la calidad de vida que en la mayoría de las
                    ocasiones se ve deteriorada de manera importante por este
                    padecimiento.
                  </p>
                  <p>
                    La primera cita en nuestra clínica tiene una duración
                    aproximada de dos horas, ya que de ser necesario se le
                    realizarán los estudios clínicos requeridos para obtener el
                    diagnóstico correspondiente. Lo primero que se debe hacer es
                    una entrevista clínica con el paciente, la cual nos servirá
                    para obtener tanto su historia clínica, como la información
                    necesaria para que nosotros podamos categorizar el tipo de
                    tinnitus o acúfeno.
                  </p>
                  <p>
                    Dependiendo de esta valoración se le pedirá al paciente la
                    elaboración de algunos estudios que posiblemente se puedan
                    requerir y que complementen el diagnóstico, los cuales son:
                  </p>
                  <List>
                    <ListItem>Audiometría tonal completa</ListItem>
                    <ListItem>Timpanometría</ListItem>
                    <ListItem>Emisiones otoacústicas</ListItem>
                  </List>
                  <p>
                    Si anteriormente le han realizado algunos estudios médicos
                    en relación a su padecimiento puede traerlos para su
                    revisión e integración a su expediente.
                  </p>
                </Content>
              </AccordionPanel>
              <AccordionTab>Tinnitus Retraining Therapy</AccordionTab>
              <AccordionPanel>
                <Content>
                  <p>
                    El tratamiento para dejar de percibir el molesto acúfeno es
                    a través de la implementación de la Terapia
                    Internacionalmente conocida como TRT, cuyo significado es
                    Tinnitus Retraining Therapy, es decir Terapia del
                    Reentrenamiento del Acúfeno.
                  </p>
                  <p>
                    El TRT fue creada por el Ph.D., Sc.D., M.B.A. Pawel J.
                    Jastreboff a mediados de los años 80´s mientras trabajaba en
                    la Universidad de Yale. En 1988 el Dr. Jonathan Hazell y el
                    Audiólogo Jacqui Sheldrake en Londres, adoptaron la terapia
                    TRT como el tratamiento principal para los pacientes con
                    problema de Tinnitus. Al poco tiempo de haber implementado
                    la terapia se dieron cuenta que este programa era mucho más
                    efectivo que muchos medicamentos u otras técnicas de
                    enmascaramiento.
                  </p>
                  <p>
                    Siempre que le preguntan al Sr. Pawel J. Jasttreboff la
                    esencia del TRT él explica lo siguiente. "Es un método
                    destinado a enseñar nuevamente al cerebro a procesar las
                    reacciones corporales, tales como la ansiedad, tristeza,
                    inseguridad, etc., inducidas por el molesto tinnitus,
                    enseñándole al paciente a disminuir su percepción,
                    entrenando a su cerebro a que trate el acúfeno como el ruido
                    de un refrigerador en la cocina, cuyo sonido es
                    imperceptible a menos de que se le ponga atención, sin ser
                    para nada algo que duela y sea MOLESTO."
                  </p>
                  <p>
                    El tratamiento GARANTIZA la disminución del molesto Acúfeno
                    (Tinnitus) a tal grado que el paciente deje de percibirlo,
                    permitiéndole SIGNIFICATIVAMENTE mejorar su calidad de vida
                    y el entorno de esta. Por lo tanto nuestros pacientes,
                    podrán disfrutar de ir al cine sin tener que sobreproteger
                    sus oídos por el dolor que puede causar el sonido, volver a
                    dormir tranquilamente, disminuir su tristeza, ansiedad, etc.
                  </p>
                  <p>
                    Todos nuestros pacientes han sido tratados exitosamente con
                    la terapia TRT, quienes han reportado un 90% de mejora
                    significativa al ser tratados en la Clínica. Adicional a
                    esto Evolución Auditiva es la primera Clínica en México
                    especializada en el tratamiento del Acúfeno o Tinnitus, la
                    cual está certificada por la Fundación Jastreboff Hearing
                    Disorders; Emory University
                  </p>
                  <figure>
                    <img src={imgTeam} alt="tinnitus-pjj.com" />
                    <figcaption>
                      En la foto se puede apreciar del lado izquierdo a la Dra.
                      Margaret M. Jastreboff, en medio la TRT Ma. Del
                      PilarBuitrago G. y del lado derecho al Dr. Pawel J.
                      Jastreboff{" "}
                      <a href="http://www.tinnitus-pjj.com/" target="_blank" rel="noreferrer">
                        tinnitus-pjj.com
                      </a>
                    </figcaption>
                  </figure>
                </Content>
              </AccordionPanel>
            </Accordion>
          </Content>
        </Wrapper>
      </Container>
      <Contact />
    </>
  );
}

export default Tinnitus;
