import "../sass/contact-info.sass";

function ContactInfo({ icon, children }) {
  return (
    <div className="contact-info">
      <div className="contact-info__icon">
        <span className="material-icons">{icon}</span>
      </div>
      <div className="contact-info__text">{children}</div>
    </div>
  );
}

export default ContactInfo;
