import {useEffect } from "react";
import PageTitle from "../components/PageTitle";
import Container from "../components/Container";
import Wrapper from "../components/Wrapper";
import Content from "../components/Content";
import Contact from "../components/Contact";
import List from "../components/List";
import ListItem from "../components/ListItem";

function Aviso() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTitle
        title="Aviso de Privacidad"
        subtitle="Evolución Auditiva S.A de C.V"
      />
      <Container variant="white">
        <Wrapper variant="lg">
          <Content>
            <p>
              La empresa Evolución Auditiva S.A de C.V (Evolución Auditiva), con
              domicilio en Calle Shakespeare No. 15-601, Col. Anzures, Alcaldía
              Miguel Hidalgo, C.P. 11590, México, Ciudad de México, es la
              responsable del uso y protección de sus datos personales y al
              respecto le informa lo siguiente:
            </p>

            <p>
              Los datos personales que recabamos, los utilizaremos para las
              siguientes finalidades que son necesarias para el servicio que
              solicita:
            </p>
            <ul>
              <li>Darle una asesoría adecuada sobre aparatos auditivos</li>
            </ul>

            <p>
              De manera adicional, utilizaremos su información personal para las
              siguientes finalidades que no son necesarias para el servicio
              solicitado, pero que nos permiten y facilitan brindarle una mejor
              atención:
            </p>
            <List>
              <ListItem>
                Informar sobre nuevos productos y servicios que estén
                relacionados con los ya adquiridos por el cliente
              </ListItem>
              <ListItem>Informar sobre cambios en nuestros servicios</ListItem>
              <ListItem>Evaluar la calidad del servicio</ListItem>
              <ListItem>
                {" "}
                Realizar estudios internos sobre las preferencias de los
                clientes
              </ListItem>
            </List>
            <p>
              En caso de que no desee que sus datos personales sean tratados
              para estos fines adicionales dé click aquí y llene el formato
              correspondiente.
            </p>

            <h4>¿Qué datos personales utilizamos para estos fines?</h4>

            <p>
              Para llevar a cabo las actividades descritas en el presente aviso
              de privacidad, utilizaremos los siguientes datos personales:
              nombre completo, números telefónicos, correo electrónico.
            </p>

            <h4>
              ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u
              oponerse a su uso?
            </h4>
            <p>
              Usted tiene derecho a conocer qué datos personales tenemos de
              usted, para qué los utilizamos y las condiciones del uso que les
              damos (Acceso). Asimismo, es su derecho solicitar la corrección de
              su información personal en caso de que esté desactualizada, sea
              inexacta o incompleta (Rectificación); que la eliminemos de
              nuestros registros o bases de datos cuando considere que la misma
              no está siendo utilizada conforme a los principios, deberes y
              obligaciones previstas en la normativa (Cancelación); así como
              oponerse al uso de sus datos personales para fines específicos
              (Oposición). Estos derechos se conocen como derechos ARCO.
            </p>

            <p>
              Para conocer el procedimiento y requisitos para el ejercicio de
              los derechos ARCO, usted podrá llamar al siguiente número
              telefónico 5552077261, o bien ponerse en contacto con nuestro
              Departamento de Privacidad, que dará trámite a las solicitudes
              para el ejercicio de estos derechos, y atenderá cualquier duda que
              pudiera tener respecto al tratamiento de su información. Los datos
              de contacto son los siguientes: Calle Shakespeare No. 15-601, Col.
              Anzures, Alcaldía Miguel Hidalgo, C.P. 11590, México, Ciudad de
              México, teléfono 5552077261, correo electrónico{" "}
              <a href="mailto:lcamarillo@evolucionauditiva.com">
                lcamarillo@evolucionauditiva.com
              </a>
            </p>

            <h4>
              ¿Cómo puede revocar su consentimiento para el uso de sus datos
              personales?
            </h4>
            <p>
              Usted puede revocar el consentimiento que, en su caso, nos haya
              otorgado para el tratamiento de sus datos personales. Sin embargo,
              es importante que tenga en cuenta que no en todos los casos
              podremos atender su solicitud o concluir el uso de forma
              inmediata, ya que es posible que por alguna obligación legal
              requiramos seguir tratando sus datos personales. Asimismo, usted
              deberá considerar que, para ciertos fines, la revocación de su
              consentimiento implicará que no le podamos seguir prestando el
              servicio que nos solicitó, o la conclusión de su relación con
              nosotros.
            </p>

            <p>
              Para revocar su consentimiento deberá presentar su solicitud en
              Calle Shakespeare No. 15-601, Col. Anzures, Alcaldía Miguel
              Hidalgo, C.P. 11590, México, Ciudad de México, teléfono
              5552077261, correo electrónico{" "}
              <a href="mailto:lcamarillo@evolucionauditiva.com">
                lcamarillo@evolucionauditiva.com
              </a>
            </p>
            <h4>
              ¿Cómo puede limitar el uso o divulgación de su información
              personal?
            </h4>
            <p>
              Con objeto de que usted pueda limitar el uso y divulgación de su
              información personal, le ofrecemos los siguientes medios:
            </p>
            <List>
              <ListItem>
                Su inscripción en el Registro Público para Evitar Publicidad,
                que está a cargo de la Procuraduría Federal del Consumidor, con
                la finalidad de que sus datos personales no sean utilizados para
                recibir publicidad o promociones de empresas de bienes o
                servicios. Para mayor información sobre este registro, usted
                puede consultar el portal de Internet de la PROFECO, o bien
                ponerse en contacto directo con ésta.
              </ListItem>
              <ListItem>
                Su registro en el listado de exclusión “Listado de exclusión
                Evolución Auditiva”, a fin de que sus datos personales no sean
                tratados para fines mercadotécnicos, publicitarios o de
                prospección comercial por nuestra parte. Para más información
                llamar al número telefónico 5552077321 o enviar un correo
                electrónico a la siguiente dirección{" "}
                <a href="mailto:lcamarillo@evolucionauditiva.com">
                  lcamarillo@evolucionauditiva.com
                </a>
              </ListItem>
            </List>

            <h4>¿Cómo puede conocer los cambios a este aviso de privacidad?</h4>
            <p>
              El presente aviso de privacidad puede sufrir modificaciones,
              cambios o actualizaciones derivadas de nuevos requerimientos
              legales; de nuestras propias necesidades por los productos o
              servicios que ofrecemos; de nuestras prácticas de privacidad; de
              cambios en nuestro modelo de negocio, o por otras causas. Nos
              comprometemos a mantenerlo informado sobre los cambios que pueda
              sufrir el presente aviso de privacidad, a través de la publicación
              escrita y exhibida al público en nuestra sucursal y a través de su
              actualización en nuestra página web.
            </p>
            <p>Última actualización 10/01/2022</p>
          </Content>
        </Wrapper>
      </Container>
      <Contact />
    </>
  );
}

export default Aviso;
