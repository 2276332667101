import { useEffect, useState } from "react";
import Wrapper from "./Wrapper";
import Navbar from "./Navbar";
import Aside from "./Aside";
import imgLogoEvolucion from "../static/logo-evolucion-auditiva.svg";
import imgLogoWidex from "../static/logo-widex.svg";
import imgLogoPhonak from "../static/logo-phonak.svg";
import { Link } from "react-router-dom";
import "../sass/header.sass";

function Header() {
  const [isSticky, setSticky] = useState(false);
  const [active, setActive] = useState(false);

  const handleAside = () => {
    setActive(!active);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 80) {
        setSticky(true);
        return;
      }
      setSticky(false);
    });
  }, [isSticky]);

  return (
    <>
      <header className={isSticky ? "header active" : "header"}>
        <Wrapper variant="xl">
          <div className="header__content">
            <img
              src={imgLogoEvolucion}
              alt="Logotipo Evolución Auditiva"
              className="header__content__logo"
            />

            <div className="header__right">

              <div className="header__contact-bar">
                <div className="header__contact-bar__icon">
                  <span className="material-icons">phone_in_talk</span>
                </div>
                <div className="header__contact-bar__item">
                  <Link to="/">55 5207-7321</Link>
                </div>
                <div className="header__contact-bar__item">
                  <Link to="/">55 5207-7261</Link>
                </div>
                <div className="header__contact-bar__item">
                  <Link to="/">55 5295-5132</Link>
                </div>
              </div>

              <div className="header__brands">
                <img
                  src={imgLogoWidex}
                  alt="Logotipo Widex"
                  className="header__brands__brand"
                />
                <div className="header__brands__splitter"></div>
                <img
                  src={imgLogoPhonak}
                  alt="Logotipo Phonak"
                  className="header__brands__brand"
                />
              </div>

              <div className="header__menu" onClick={handleAside}>
                <span className="material-icons">menu</span>
              </div>

            </div>

          </div>
        </Wrapper>
        <Navbar />
      </header>
      <Aside active={active} setActive={setActive} />
    </>
  );
}

export default Header;
