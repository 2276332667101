import { Link } from "react-router-dom";
import "../sass/contact-form.sass";
import Button from "./Button";

function ContactForm() {
  return (
    <form className="contact-form">
      <div className="form-group">
        <label htmlFor="name">Nombre:</label>
        <input type="text" id="name" placeholder="Nombre" />
      </div>
      <div className="contact-form__split">
        <div className="form-group">
          <label htmlFor="email">Correo Electrónico:</label>
          <input type="email" id="email" placeholder="Correo Electrónico" />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Teléfono:</label>
          <input type="text" id="phone" placeholder="Teléfono" />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="message">Mensaje:</label>
        <textarea id="message" rows="5"></textarea>
      </div>
      <Button variant="fill" title="Enviar" />
      <p>
        Consulta nuestro{" "}
        <Link to="/aviso-de-privacidad">Aviso de Privacidad</Link>
      </p>
    </form>
  );
}

export default ContactForm;
