import { useEffect } from "react";
import Contact from "../components/Contact";
import PageTitle from "../components/PageTitle";
import Split from "../components/Split";
import imgStudies from "../static/estudios-audiologicos.jpg";
import List from "../components/List";
import ListItem from "../components/ListItem";
import Content from "../components/Content";
import Container from "../components/Container";
import Wrapper from "../components/Wrapper";
import Flex from "../components/Flex";
import imgAudiometria from "../static/1c.gif";
import imgTemanometria from "../static/2c.jpg";
import imgOtoacusticas from "../static/3c.png";
import imgPotenciales from "../static/4c.jpg";
import imgVideonistagmografia from "../static/5c.jpg";

function EstudiosAudiologicos() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTitle
        title="Estudios Audiologicos"
        subtitle="Contamos con los equipos más modernos de diagnóstico"
      />
      <Split image={imgStudies} alt="Widex">
        <Content>
          <h2>Evolución Auditiva</h2>
          <p>
            En Evolución Auditiva contamos con los más modernos equipos de
            diagnóstico para realizar los estudios audiológicos que requieren
            nuestros pacientes:
          </p>
          <List>
            <ListItem>Audiometría tonal con logoaudiometría</ListItem>
            <ListItem>Timpanometría</ListItem>
            <ListItem>Emisiones otoacústicas</ListItem>
            <ListItem>Potenciales evocados</ListItem>
            <ListItem>Videonistagmografía</ListItem>
          </List>
        </Content>
      </Split>
      <Container variant="white" className="studies">
        <Wrapper variant="lg">
          <div className="studies-content">
            <Content>
              <Flex>
                <div className="flex__item">
                  <Content>
                    <p>
                      <strong className="blue--primary">
                        Audiometría tonal con logoaudiometría:
                      </strong>{" "}
                      estudio que mide la agudeza auditiva en relación con las
                      diferentes frecuencias de sonido. Se lleva a cabo en una
                      cabina sonoamortiguada utilizando un Audiómetro el cual
                      emite señales de test a varios niveles de presión sonora y
                      a varias frecuencias. La persona cuya audición se desea
                      medir deberá indicar al profesional cuándo puede oír la
                      señal.
                    </p>
                    <p>
                      <strong className="blue--primary">
                        Logoaudiometría:
                      </strong>{" "}
                      evalúa la capacidad de comprensión del lenguaje hablado a
                      través de la discriminación de la palabra, es decir,
                      contabilizar cuántas palabras se pueden repetir
                      correctamente.
                    </p>
                    <p>
                      La combinación de estas dos pruebas es importante ya que
                      OÍR significa percibir el sonido y COMPRENDER es entender
                      el significado de los sonidos o palabras.
                    </p>
                  </Content>
                </div>
                <div className="flex__item">
                  <img
                    src={imgAudiometria}
                    alt="Audiometría tonal con logoaudiometría"
                  />
                </div>
              </Flex>
              <Flex variant="reverse">
                <div className="flex__item">
                  <img src={imgTemanometria} alt="Timpanometría" />
                </div>
                <div className="flex__item">
                  <p>
                    <strong className="blue--primary">Timpanometría:</strong>{" "}
                    estudio auditivo que mide la resistencia del oído medio al
                    paso de la energía sonora. Es una prueba en la cual se varía
                    la presión del canal auditivo, evaluando la condición y
                    movimiento del tímpano (membrana timpánica) y se utiliza
                    para detectar trastornos del oído medio.
                  </p>
                </div>
              </Flex>
              <Flex>
                <div className="flex__item">
                  <p>
                    <strong className="blue--primary">
                      Emisiones otoacústicas:
                    </strong>{" "}
                    estudio auditivo que permite identificar el estado de las
                    células ciliadas externas de la cóclea.
                  </p>
                </div>
                <div className="flex__item">
                  <img src={imgOtoacusticas} alt="Emisiones otoacústicas" />
                </div>
              </Flex>
              <Flex>
                <div className="flex__item">
                  <p>
                    <strong className="blue--primary">
                      Potenciales evocados auditivos:
                    </strong>{" "}
                    Prueba que estudia la actividad eléctrica neuronal en
                    respuesta a diferentes estímulos auditivos
                  </p>
                </div>
                <div className="flex__item">
                  <img
                    src={imgPotenciales}
                    alt="Potenciales evocados auditivos"
                  />
                </div>
              </Flex>
              <Flex>
                <div className="flex__item">
                  <p>
                    <strong className="blue--primary">
                      Videonistagmografía:
                    </strong>{" "}
                    En esta prueba aprovechamos la existencia de un reflejo
                    entre el oído interno y el ojo, reflejo
                    vestíbulo-óculomotor, que hace referencia al movimiento
                    ocular provocado por el estímulo del sistema vestibular. De
                    manera que podemos saber el nivel de funcionamiento de los
                    diferentes sistemas que intervienen en el mantenimiento del
                    equilibrio (oído y sistema nervioso central
                    fundamentalmente)
                  </p>
                </div>
                <div className="flex__item">
                  <img src={imgVideonistagmografia} alt="Videonistagmografía" />
                </div>
              </Flex>
            </Content>
          </div>
        </Wrapper>
      </Container>
      <Contact />
    </>
  );
}

export default EstudiosAudiologicos;
