import Wrapper from "./Wrapper";
import img1 from "../static/1a.jpg";
import img2 from "../static/2a.jpg";
import img3 from "../static/3a.jpg";
import img4 from "../static/4a.jpg";
import img5 from "../static/5a.jpg";
import "../sass/devices.sass";

function Devices() {
  return (
    <section className="devices">
      <Wrapper variant="xl">
        <div className="devices__container">
          <div className="devices__item">
            <img src={img1} alt="Widex" />
          </div>
          <div className="devices__item">
            <img src={img2} alt="Widex" />
          </div>
          <div className="devices__item">
            <img src={img3} alt="Widex" />
          </div>
          <div className="devices__item">
            <img src={img4} alt="Widex" />
          </div>
          <div className="devices__item">
            <img src={img5} alt="Widex" />
          </div>
        </div>
      </Wrapper>
    </section>
  );
}

export default Devices;
