import { useEffect } from "react";
import Contact from "../components/Contact";
import PageTitle from "../components/PageTitle";
import Split from "../components/Split";
import Content from "../components/Content";
import imgAjustes from "../static/ajustes-y-mas.jpg";
import Devices from "../components/Devices";

function Ajustes() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTitle
        title="Ajustes y más"
        subtitle="Ajustes y adapataciones de diferentes marcas"
      />
      <Split image={imgAjustes} alt="Widex">
        <Content>
          <h2>Servicios</h2>
          <p>
            <strong className="blue--primary">Ajustes:</strong> debido a que la audición podría ir cambiando, adaptamos la
            programación de los auxiliares auditivos a la audición presente del
            paciente.
          </p>
          <p>
           <strong className="blue--primary">Moldes:</strong> se realizan a la medida del canal auditivo de cada paciente
            para una mejor escucha de los aparatos auditivos.
          </p>
          <p>
            <strong className="blue--primary">Mantenimiento de aparatos auditivos:</strong> mantenga en buen estado sus
            auxiliares con un mantenimiento regular.
          </p>
          <p>
            <strong className="blue--primary">Reparaciones:</strong> al ser distribuidores directos de Phonak y Widex estas
            marcas nos respaldan en la revisión y/o reparación de los aparatos
            que adaptamos a nuestros pacientes.
          </p>
          <p>
           <strong className="blue--primary">Consumibles:</strong> tenemos disponibles consumibles para los aparatos
            auditivos como pilas, filtros, olivas, receptores y kits de
            limpieza.
          </p>
          <p>
            <strong className="blue--primary">Accesorios:</strong> contamos con accesorios con los que le puede sacar mayor
            provecho de sus aparatos auditivos como micrófonos de apoyo en
            ambientes ruidosos, transmisores del sonido de su TV o de su
            teléfono a los aparatos auditivos, cargadores para auxiliares
            recargables, deshumidificadores electrónicos entre otros.
          </p>
        </Content>
      </Split>
      <Devices />
      <Contact />
    </>
  );
}

export default Ajustes;
