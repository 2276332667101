import "../sass/banner.sass";
import videoBanner from "../static/banner-1.mp4";
import imgLogoWidex from "../static/logo-widex-blanco.svg";
import imgLogoPhonak from "../static/logo-phonak-blanco.svg";

function Banner() {
  return (
    <section className="banner">
      <video autoPlay loop muted className="video__player">
        <source src={videoBanner} type="video/mp4" />
      </video>
      <div className="banner__content">
        <p>
          Descuentos hasta del <span>40%</span> en pago de contado* o 12 meses
          sin intereses con BBVA
        </p>
        <p>
          <small>*Consulte productos participantes</small>
        </p>
        <div className="banner__content__logos">
          <div className="banner__content__logos__item">
            <img src={imgLogoWidex} alt="Logo Widex" />
          </div>
          <div className="banner__content__logos__item">
            <img src={imgLogoPhonak} alt="BBVA" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
