import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Contact from "../components/Contact";
import PageTitle from "../components/PageTitle";
import Split from "../components/Split";
import Content from "../components/Content";
import List from "../components/List";
import ListItem from "../components/ListItem";
import Button from "../components/Button";
import imgAdvisory from "../static/advisory.jpg";
import imgBenefits from "../static/benefits.jpg";
import Banner from "../components/Banner";
import Sample from "../components/Sample";

function AparatosAuditivos() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  return (
    <>
      <PageTitle
        title="Aparatos Auditivos"
        subtitle="¡Que tu audición no te limite!"
      />
      <Banner />
      <Sample margin="mb mt" />
      <Split image={imgAdvisory} alt="Widex" id="asesoria-gratuita">
        <Content>
          <div>
            <h2>Asesoría Gratuita</h2>
            <p className="important">
              Cada caso es diferente, te asesoramos para que encuentres el
              aparato auditivo ideal para ti o tu ser querido
            </p>
          </div>
          <p>
            Si detectas que tu nivel de audición o el de alguno de tus
            familiares ha disminuido acude con nosotros. Nuestro personal te
            asesorará sobre tu pérdida auditiva y las mejores opciones de
            aparatos auditivos.
          </p>
          <List>
            <ListItem>Para niño o adulto</ListItem>
            <ListItem>
              Pérdida auditiva leve, moderada, severa o profunda
            </ListItem>
            <ListItem>Distintos niveles de entendimiento</ListItem>
            <ListItem>Pérdida en un oído o en los dos</ListItem>
            <ListItem>Diferentes diseños y tamaños</ListItem>
            <ListItem>Con opciones de conectividad</ListItem>
            <ListItem>De pilas o recargables</ListItem>
          </List>
        </Content>
      </Split>
      <Split image={imgBenefits} alt="Widex" side="right">
        <Content>
          <div>
            <h2>Beneficios</h2>
            <p className="important">No permitas que se aíslen</p>
          </div>
          <List>
            <ListItem>
              Reintegra a las personas a sus actividades cotidianas
            </ListItem>
            <ListItem>Aumenta el rendimiento escolar y laboral</ListItem>
            <ListItem>Facilita la participación en las conversaciones</ListItem>
            <ListItem>Disminuye la sensación de aislamiento y soledad</ListItem>
            <ListItem>Reduce la sensación de frustración</ListItem>
            <ListItem>
              Evita los riesgos de no escuchar sonidos de advertencia (alarmas,
              claxon, sirenas, etc)
            </ListItem>
            <ListItem>Refuerza la seguridad y la autosuficiencia</ListItem>
            <ListItem>Mejora significativamente la calidad de vida</ListItem>
          </List>
          <div className="button-container">
            <Button
              variant="fill"
              title="Pérdida Auditiva"
              onClick={() => navigate("/perdida-auditiva")}
            />
            <Button
              variant="outline"
              title="Tinnitus"
              onClick={() => navigate("/zumbido-de-oidos")}
            />
          </div>
        </Content>
      </Split>
      <Contact />
    </>
  );
}

export default AparatosAuditivos;
