import { useEffect } from "react";
import ContactInfo from "../components/ContactInfo";
import Content from "../components/Content";
import PageTitle from "../components/PageTitle";
import Wrapper from "../components/Wrapper";
import ContactMap from "../components/ContactMap";
import ContactForm from "../components/ContactForm";
import Container from "../components/Container";
import "../sass/page-contact.sass";

function Contacto() {

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  return (
    <>
      <PageTitle
        title="Contacto"
        subtitle="Somos especialistas en Audiología"
      />
        <Container className="contact-page" variant="gray">
          <Wrapper variant="xl">
            <div className="contact-page__container">
              <ContactForm />
              <Content>
                <h2>Contacto</h2>
                <p className="important">
                  Las audioprotesistas, de acuerdo a los resultados de una
                  Valoración auditiva previa, podrán asesorarte sobre tu pérdida
                  auditiva y sobre las mejores opciones de aparatos auditivos
                  que requieras.
                </p>
                <ContactInfo icon="place">
                  <p>
                    Calle Shakespeare No. 15 Piso 6, interior 601, Col. Anzures,
                    C.P. 11590, Alcaldía Miguel Hidalgo, CDMX
                  </p>
                </ContactInfo>
                <ContactInfo icon="phone_in_talk">
                  <p>Agenda tu cita en los teléfonos:</p>
                  <p>
                    55 5207-7321 <span>|</span> 55 5207-7261
                    <span>|</span> 55 5295-5132
                  </p>
                </ContactInfo>
              </Content>
            </div>
          </Wrapper>
        </Container>
      <ContactMap />
    </>
  );
}

export default Contacto;
