import "../sass/button.sass";

function Button({ variant, title, onClick }) {
  return (
    <button className={`btn ${variant}`} onClick={onClick}>
      {title}
    </button>
  );
}

export default Button;
