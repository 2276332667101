import "../sass/sample.sass";
import Wrapper from "./Wrapper";
import imgWidexProduct from "../static/widex-product.jpg";
import imgPhonakProduct from "../static/phonak-product.jpg";

function Sample({margin}) {
  return (
    <section className={`sample ${margin}`}>
      <Wrapper  variant="xl">
        <div className="sample__content">
          <div>
            <div className="sample__card">
              <img src={imgWidexProduct} alt="Productos Widex" />
            </div>
          </div>
          <div>
            <div className="sample__card">
              <img src={imgPhonakProduct} alt="Productos Phonak" />
            </div>
          </div>
        </div>
      </Wrapper>
    </section>
  )
}

export default Sample